import { ref } from '@vue/composition-api'

export default function service() {
  const serviceForm = ref({
    question: '',
    answer: '',
    question_ar: '',
    answer_ar: '',
  })

  const viewData = ref({})

  return {
    serviceForm,
    viewData,
  }
}
