<template>
  <div>
    <general-table
      ref="faqsTable"
      api-url="faqs"
      add-type="Add FAQ"
      type="modal"
      :columns="columns"
      :selectable="false"
      add-component-name="add-faq"
      edit-component="edit-faq"
      :view-content="false"
      :search-box="false"
      guard="friends"
    />
    <div>
      <b-modal
        id="faq"
        ref="genmodal"
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        :title="$store.state.generalIds.id ? 'Update Question' : 'Add New Question'"
      >
        <add-edit-faq />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditFAQ from './control-faqs/AddEditFAQ.vue'

export default {
  components: { GeneralTable, 'add-edit-faq': AddEditFAQ },
  data() {
    return {
      columns: [
        { key: 'id', sortable: false },
        { key: 'question', label: 'English Question' },
        { key: 'answer', label: 'English Answer' },
        { key: 'question_ar', label: 'Arabic Question' },
        { key: 'answer_ar', label: 'Arabic Answer' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
