<template>
  <div>
    <validation-observer
      ref="faqForm"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="QuestionEn"
            rules="required"
          >
            <b-form-group
              label="English Question"
              label-for="QuestionEn"
            >
              <b-form-input
                id="QuestionEn"
                v-model="faqForm.question"
                :state="getValidationState(validationContext)"
                trim
                placeholder="English Question"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="AnswerEn"
            rules="required"
          >
            <b-form-group
              label="English Answer"
              label-for="AnswerEn"
            >
              <b-form-input
                id="AnswerEn"
                v-model="faqForm.answer"
                :state="getValidationState(validationContext)"
                trim
                placeholder="English Answer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="QuestionAr"
            rules="required"
          >
            <b-form-group
              label="Arabic Question"
              label-for="QuestionAr"
            >
              <b-form-input
                id="QuestionAr"
                v-model="faqForm.question_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Arabic Question"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="AnswerAr"
            rules="required"
          >
            <b-form-group
              label="Arabic Answer"
              label-for="AnswerAr"
            >
              <b-form-input
                id="AnswerAr"
                v-model="faqForm.answer_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Arabic Answer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="addService"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import faq from '../faqs'

export default {
  data() {
    return {
      required,
      faqForm: {},
      id: this.$store.state.generalIds.id,
      loader: false,
      validationErrors: {},
      errors: {},
      services: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { faqForm } = faq()

    return {
      getValidationState,
      faqForm,
    }
  },
  mounted() {
    this.viewFAQ()
  },
  methods: {
    closeModal() {
      this.faqForm = {
        question: '',
        answer: '',
        question_ar: '',
        answer_ar: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('faq')
    },
    addService() {
      if (this.id) {
        this.loader = true
        axios.put(`faqs/${this.id}`, this.faqForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.faqsTable.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('faqs', this.faqForm).then(res => {
          this.$parent.$parent.$parent.$refs.faqsTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          this.errors = error?.response?.data?.errors
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewFAQ() {
      if (this.id) {
        axios.get(`faqs/${this.id}`).then(res => {
          if (res.status === 200) {
            this.faqForm = res.data?.data
          }
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
